import React, { FunctionComponent } from 'react';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { Label } from '../../common-components/label/label.component';
import { empty, selectArrow, selectArrowEmployee } from './select-box.css';

const SELECT_SIZE_MAP = {
	SMALL: 'input-small f6',
	DEFAULT: 'input f5',
	LARGE: 'input-large f4'
};

const SELECT_EMPTY_SIZE_MAP = {
	SMALL: `${empty} input-small f6 pl4 pr1`,
	DEFAULT: `${empty} input f5 pl4 pr3`,
	LARGE: `${empty} input-large f4 pl5 pr4`
};

export type SelectSize = keyof typeof SELECT_SIZE_MAP;

export type SelectOptionProps = {
	name: string;
	value: string;
	disabled?: boolean;
	selected?: boolean;
	required?: boolean;
};

export type SelectBoxProps = {
	name?: string;
	options: SelectOptionProps[];
	ariaLabel: string;
	selectSize?: SelectSize;
	hideArrow?: boolean;
	automationHook?: string;
	analyticsHook?: string;
	className?: string;
	required?: boolean;
	id?: string;
	borderStyle?: 'light' | 'dark';
	employee?: boolean;
} & Omit<JSX.IntrinsicElements['select'], 'className' | 'ref'>;

export type SelectBoxWithLabelProps = SelectBoxProps & {
	label: string;
	id: string;
	selectClassName?: string;
};

export const SelectBox = React.forwardRef<HTMLSelectElement, SelectBoxProps>(
	(
		{
			options,
			ariaLabel,
			selectSize = 'DEFAULT',
			hideArrow = false,
			automationHook,
			analyticsHook,
			className,
			borderStyle = 'light',
			employee = false,
			...selectProps
		},
		ref
	) => {
		const arrowClass = selectProps.multiple || hideArrow ? '' : selectArrow;
		const sizeClass = options.length === 0 ? SELECT_EMPTY_SIZE_MAP[selectSize] : SELECT_SIZE_MAP[selectSize];
		const disabledClass = selectProps.disabled ? 'not-allowed' : 'pointer';
		const borderClass = employee ? 'b--theme-internal' : borderStyle === 'light' ? 'b--theme-tertiary' : 'b--theme-grey-darker';
		const showInitialEmptyOption = selectProps.required === false;
		const employeeClass = employee ? `bg-theme-internal-lighter b--theme-internal theme-internal ${selectArrowEmployee}` : '';
		return (
			<select
				defaultValue={showInitialEmptyOption ? '' : undefined}
				data-testid="SelectBox"
				{...selectProps}
				ref={ref}
				className={`br2 ba bg-theme-white theme-grey-darker input-reset ${borderClass} ${
					className || ''
				} ${arrowClass} ${sizeClass} ${disabledClass} ${hideArrow ? 'ph3' : 'pl3 pr5'} ${employeeClass}`}
				data-automation={generateDataSelector('select', automationHook)}
				data-analytics={generateDataSelector('select', analyticsHook)}
				aria-label={ariaLabel}>
				{showInitialEmptyOption && <option value="" hidden disabled></option>}
				{options.map((option) => (
					<SelectOption {...option} key={`${option.name}:${option.value}`} />
				))}
			</select>
		);
	}
);

export const SelectBoxWithLabel = React.forwardRef<HTMLSelectElement, SelectBoxWithLabelProps>(
	({ label, className, id, options, selectClassName, required, ...selectProps }, ref) => (
		<Label label={label} className={className} id={id} required={required}>
			<SelectBox options={options} {...selectProps} id={id} required={required} ref={ref} className={selectClassName} />
		</Label>
	)
);

export const SelectOption: FunctionComponent<SelectOptionProps> = ({ name, value, disabled, selected }) => (
	<option value={value} selected={selected} disabled={disabled}>
		{name}
	</option>
);

import { Facet, ProductStatusEnum, RangeBoundEnum } from '../../__generated__/graphql-client-types';
import {
	FACET_ID_SEPARATOR,
	FACET_MONEY_MAX_DIGITS,
	FACET_MONEY_MIN_DIGITS,
	FACET_MONEY_STRIP_INTEGER_ZEROS,
	FACET_NUMBER_MAX_DIGITS,
	FACET_NUMBER_MIN_DIGITS,
	FACET_NUMBER_STRIP_INTEGER_ZEROS,
	FACET_SEPARATOR,
	QUICKSHIP_FACET_DISPLAY_VALUE,
	QUICKSHIP_FACET_GROUP_DISPLAY_NAME,
	QUICKSHIP_FACET_GROUP_NAME,
	RANGE_FACET_VALUE_SEPARATOR,
	REGEX_FACET_ID_BOOLEAN,
	REGEX_FACET_PARTS
} from '../../constants/search';
import { FacetGroup, FacetGroupBase, FacetValueBase, SelectedFacet, SelectedFacetGroup } from '../../types/search.types';

const BOOLEAN_FACET_DISPLAY_VALUE_MAP = {
	true: 'Yes',
	false: 'No'
};

export const getBooleanFacetDisplayValue = (value: string): string => BOOLEAN_FACET_DISPLAY_VALUE_MAP[value] ?? value;

/**
 * Get a query string representation of facet values.
 */
export function getFacetsQueryString(facets: Facet[] | null): string {
	if (!facets) {
		return '';
	}
	return facets
		.map((facet) => {
			const rangeBound = facet.rangeBound ? `${FACET_ID_SEPARATOR}${encodeURIComponent(facet.rangeBound.toLowerCase())}` : '';
			return `${encodeURIComponent(facet.id)}${rangeBound}${FACET_ID_SEPARATOR}${encodeURIComponent(facet.value)}`;
		})
		.join(FACET_SEPARATOR);
}

function getRangeBound(value?: string): RangeBoundEnum | undefined {
	const upperValue = value?.toUpperCase();
	return upperValue === 'LOW' || upperValue === 'HIGH' ? upperValue : undefined;
}

/**
 * Parse a facets query string into a Facet array.
 */
export function parseFacetsQueryString(facetsParam: string | null): Facet[] {
	if (!facetsParam) {
		return [];
	}
	return facetsParam.split(FACET_SEPARATOR).reduce<Facet[]>((result, facetString) => {
		// Split out facet string parts for id, optional range bound, and value.
		const [, id, rangeBound, value] = REGEX_FACET_PARTS.exec(facetString) || [];
		if (id && value) {
			result.push({ id, value, rangeBound: getRangeBound(rangeBound) });
		}
		return result;
	}, []);
}

/**
 * Determine if a facet group is for quickship.
 */
export function facetGroupIsQuickShip(group: FacetGroupBase): boolean {
	return group.name.toLowerCase() === QUICKSHIP_FACET_GROUP_NAME.toLowerCase();
}

export const facetIsBoolean = (facet: FacetValueBase): boolean => REGEX_FACET_ID_BOOLEAN.test(facet.facetId);

/**
 * Determine if a facet group is a range facet group
 */
export function isRangeFacetGroup(group: FacetGroup | SelectedFacetGroup): boolean {
	return Number.isFinite(group.range?.min) && Number.isFinite(group.range?.max);
}

export type FacetRangeValues = {
	isRangeGroup: boolean;
	rangeMinimum?: number;
	rangeMaximum?: number;
	selectedMinimum?: number;
	selectedMaximum?: number;
	isSelected: boolean;
};

/**
 * Get facet range values.
 *
 * Min and max range values are rounded to 5 digits, towards their respective end so that the rounded values define a range covering all (or slightly more) of the actual, more precise range.
 * If the facet is for money (unitPrefix is "$"), then rounding is to 2 digits instead.
 *
 * Selected range values are not rounded, so they accurately indicate what was selected.
 */
export function getRangeFacetValues(group: FacetGroup, selectedFacets?: SelectedFacet[]): FacetRangeValues {
	const result: FacetRangeValues = {
		isRangeGroup: isRangeFacetGroup(group),
		isSelected: false
	};
	if (!result.isRangeGroup) {
		return result;
	}
	const numDecimals = isUnitMoney(group.unitPrefix) ? FACET_MONEY_MAX_DIGITS : FACET_NUMBER_MAX_DIGITS;
	const roundingMultipler = Math.pow(10, numDecimals);

	if (group.range) {
		result.rangeMinimum = Math.floor(group.range.min * roundingMultipler) / roundingMultipler;
		result.rangeMaximum = Math.ceil(group.range.max * roundingMultipler) / roundingMultipler;
	}

	if (selectedFacets?.length === 2) {
		const [selectedMinimum, selectedMaximum] = selectedFacets
			.map((sf) => sf.value.trim())
			.map((cleanValue) => (cleanValue === '' ? NaN : Number(cleanValue)))
			.map((numValue) => (Number.isNaN(numValue) ? undefined : numValue));
		result.selectedMinimum = selectedMinimum;
		result.selectedMaximum = selectedMaximum;
		result.isSelected = true;
	}

	return result;
}

/**
 * Get the display name for a facet group.
 */
export function getFacetGroupDisplayName(group: FacetGroupBase): string {
	return facetGroupIsQuickShip(group) ? QUICKSHIP_FACET_GROUP_DISPLAY_NAME : group.name;
}

/**
 * Get the display value for a facet value.
 */
export function getFacetDisplayValue(facet: FacetValueBase, group: FacetGroupBase): string {
	const isRangeFacet = facet.value.includes(RANGE_FACET_VALUE_SEPARATOR);
	const { unitPrefix, unitSuffix } = getUnitAffixes(group);
	const isMoney = isUnitMoney(unitPrefix);
	if (isRangeFacet) {
		return facet.value
			.split(RANGE_FACET_VALUE_SEPARATOR)
			.map((n) => Number(n))
			.sort((a, b) => a - b)
			.filter((n, ix, self) => self.indexOf(n) === ix) // Distinct to collapse same start and end.
			.map((n) => `${unitPrefix}${isMoney ? '' : ' '}${formatFacetNumber(n, isMoney)} ${unitSuffix}`.trim())
			.join(' to ');
	} else if (facetGroupIsQuickShip(group)) {
		return QUICKSHIP_FACET_DISPLAY_VALUE;
	} else if (facetIsBoolean(facet)) {
		return getBooleanFacetDisplayValue(facet.value);
	} else if (unitPrefix || unitSuffix) {
		return `${unitPrefix}${isMoney ? '' : ' '}${facet.value} ${unitSuffix}`.trim();
	}
	return facet.value;
}

export const findSelectedFacet = (
	selectedFacets: SelectedFacet[],
	facet: FacetValueBase,
	options: { isQuickShip: boolean; isRangeGroup: boolean }
) => {
	const { isQuickShip, isRangeGroup } = options;
	return selectedFacets.find(
		(selectedFacet) =>
			isQuickShip ||
			isRangeGroup ||
			(selectedFacet.facetId === facet.facetId && selectedFacet.value.toLowerCase() === facet.value.toLowerCase())
	);
};

export function statusIsNonstock(status: ProductStatusEnum[] | undefined): boolean {
	return status?.includes('nonstock') ?? false;
}

export const shouldSkipQuery = (query: string, categoryId: number, manufacturer?: string, collectionName?: string) => {
	return !query && !categoryId && (!manufacturer || !collectionName);
};

export function parseTopFacetsFeature(feature?: string): { type: string; images: boolean } {
	if (!feature) {
		return { type: '', images: false };
	}
	const [type, images] = feature.split('-');
	return {
		type,
		images: images === 'images'
	};
}

/**
 * Normalize unitPrefix and unitAffix to non-null strings.
 */
export function getUnitAffixes(group: { unitPrefix?: string | null; unitSuffix?: string | null }): {
	unitPrefix: string;
	unitSuffix: string;
} {
	return {
		unitPrefix: group.unitPrefix || '',
		unitSuffix: group.unitSuffix || ''
	};
}

export function isUnitMoney(unit?: string | null) {
	return unit === '$';
}

function formatNumber(value: number | string | undefined, numberFormatter: Intl.NumberFormat, stripIntegerZeroes = false): string {
	if (typeof value === 'undefined') {
		return '';
	}
	const number = typeof value === 'string' ? Number.parseFloat(value) : value;
	const formattedNumber = isNaN(number) ? '' : numberFormatter.format(number);
	if (stripIntegerZeroes) {
		// Leave this to the number formatter if the trailingZeroDisplay option becomes more widely supported.
		return formattedNumber.replace(/\.0+$/, '');
	}
	return formattedNumber;
}

const baseMoneyFormatterOptions: Intl.NumberFormatOptions = {
	minimumFractionDigits: FACET_MONEY_MIN_DIGITS,
	maximumFractionDigits: FACET_MONEY_MAX_DIGITS
};
const baseNumberFormatterOptions: Intl.NumberFormatOptions = {
	minimumFractionDigits: FACET_NUMBER_MIN_DIGITS,
	maximumFractionDigits: FACET_NUMBER_MAX_DIGITS
};

const facetNumberFormatter = new Intl.NumberFormat('en-US', {
	...baseNumberFormatterOptions,
	useGrouping: true
});
const facetMoneyFormatter = new Intl.NumberFormat('en-US', {
	...baseMoneyFormatterOptions,
	useGrouping: true
});
export function formatFacetNumber(n?: number | string, isMoney = false) {
	return isMoney
		? formatNumber(n, facetMoneyFormatter, FACET_MONEY_STRIP_INTEGER_ZEROS)
		: formatNumber(n, facetNumberFormatter, FACET_NUMBER_STRIP_INTEGER_ZEROS);
}

const rangeNumberFormatter = new Intl.NumberFormat('en-US', {
	...baseNumberFormatterOptions,
	useGrouping: false
});
const rangeMoneyFormatter = new Intl.NumberFormat('en-US', {
	...baseMoneyFormatterOptions,
	useGrouping: false
});
export function formatRangeNumber(n?: number | string, isMoney = false) {
	return isMoney
		? formatNumber(n, rangeMoneyFormatter, FACET_MONEY_STRIP_INTEGER_ZEROS)
		: formatNumber(n, rangeNumberFormatter, FACET_NUMBER_STRIP_INTEGER_ZEROS);
}

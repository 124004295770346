import { ApolloError } from '@apollo/client';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client/react/hooks';
import { QueryLazyOptions } from '@apollo/client/react/types/types';
import { useCookies } from '@fergdigitalcommerce/fergy-react-components';
import { logError } from 'fergy-core-react-logging';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
	Facet,
	ProductSearchFacetGroupQuery,
	ProductSearchFacetGroupQueryVariables,
	ProductSearchQuery,
	ProductSearchQueryVariables,
	ProductSortEnum,
	ProductStatusEnum,
	ProductViewEnum,
	SetProductViewPreferenceMutation,
	SetProductViewPreferenceMutationVariables
} from '../../../../client/__generated__/graphql-client-types';
import { COOKIE_SEARCH_DISABLE_PRODUCT_BOOSTS, COOKIE_SEARCH_DISABLE_REDIRECT } from '../../../constants/cookies';
import { FEATURE_FLAGS } from '../../../constants/general';
import {
	DEFAULT_CATEGORY_PAGESIZE,
	DEFAULT_PAGESIZE,
	DEFAULT_PRODUCT_SORT,
	LEGACY_FACET_ID,
	LEGACY_PARAMS,
	LEGACY_SORT_MAP,
	PAGESIZE_OPTIONS,
	RANGE_FACET_VALUE_SEPARATOR,
	SEARCH_RESULT_LIMIT
} from '../../../constants/search';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { GTMEventWrapper } from '../../../helpers/analytics/gtm/gtm-types';
import { booleanFromQueryString, QueryStringBuilder } from '../../../helpers/general-helper/general-helper';
import { createGTMEvent } from '../../../helpers/search-helper/search-analytics.helper';
import {
	facetIdMatch,
	getNewPageForPageSizeChange,
	getRangeFacetData,
	isSameFacet
} from '../../../helpers/search-helper/search-filter-helper';
import {
	getFacetsQueryString,
	parseFacetsQueryString,
	shouldSkipQuery,
	statusIsNonstock
} from '../../../helpers/search-helper/search-helper';
import { SET_PRODUCT_VIEW_PREFERENCE } from '../../../queries/customer/customer.queries';
import { PRODUCT_SEARCH, PRODUCT_SEARCH_FACET_GROUP } from '../../../queries/search/search.queries';
import { FacetWithSource, ProductOrRedirect, ProductSearchResult, SearchRedirect, SearchRequestData } from '../../../types/search.types';
import { useFeature } from '../../features/features.hooks';
import { useCustomer } from '../customer/customer.hooks';
import { useSiteViewPreference } from '../employee/employee.hooks';
import { SUPPORT_NONSTOCK_LINK } from '../../../constants/links';

const NONSTOCK_STATUS: ProductStatusEnum[] = ['stock', 'nonstock'];
const STOCK_STATUS_FACET = 'status_s';

function isProductSearchResult(productSearch?: ProductOrRedirect): productSearch is ProductSearchResult {
	return productSearch?.__typename === 'ProductSearchResult';
}

function isSearchRedirect(productSearch?: ProductOrRedirect): productSearch is SearchRedirect {
	return productSearch?.__typename === 'SearchRedirect';
}

export type UseSearchResultsPayload = {
	addFacet: (facet: Facet) => void;
	removeFacet: (facet: FacetWithSource) => void;
	addFacets: (facetsToAdd?: Facet[]) => void;
	removeFacets: (facetsToAdd?: Facet[]) => void;
	clearAndAddFacets: (facetsToAdd?: Facet[]) => void;
	clearFacets: () => void;
	setSortBy: (newSortBy: ProductSortEnum) => void;
	setPage: (newPage: number) => void;
	setPageSize: (newPageSize: number) => void;
	setNonstock: (newNonstock: boolean) => void;
	createGTMEvent: (action: TrackedEvent, data?: Record<string, any>) => GTMEventWrapper | null;
	facets: Facet[];
	sortBy: ProductSortEnum;
	page: number;
	pageSize: number;
	query: string;
	categoryId: number;
	isNonstock: boolean;
	isNewLookAndFeel: boolean;
	isNewLookAndFeelSearchHeader: boolean;
	isNewLookAndFeelProductCard: boolean;
	request: SearchRequestData;
	results?: ProductSearchResult;
	previousResults?: ProductSearchResult;
	loading: boolean;
	error?: ApolloError;
	redirectUrl?: string;
	flagged?: boolean;
};

export type UseFacetGroupResults = {
	loadFacetGroup: (options?: QueryLazyOptions<ProductSearchFacetGroupQueryVariables>) => void;
	facetGroup?: ProductSearchFacetGroupQuery['productSearchFacetGroup'];
	loading: boolean;
	called: boolean;
	error?: ApolloError;
};

function isRangeFacet(facet: Facet) {
	return facet.value.includes(RANGE_FACET_VALUE_SEPARATOR);
}

function addFacets(currentFacets: Facet[], facetsToAdd: Facet[]): Facet[] {
	let updatedFacets = [...currentFacets];
	facetsToAdd.forEach((facetToAdd) => {
		if (isRangeFacet(facetToAdd)) {
			const { lowRange, highRange } = getRangeFacetData(facetToAdd);
			updatedFacets = updatedFacets.filter((currentFacet) => !facetIdMatch(currentFacet, facetToAdd));
			updatedFacets.push(lowRange, highRange);
		} else if (!updatedFacets.some((currentFacet) => isSameFacet(currentFacet, facetToAdd))) {
			updatedFacets.push(facetToAdd);
		}
	});

	return updatedFacets;
}

function removeFacets(currentFacets: FacetWithSource[], facetsToRemove: FacetWithSource[]): FacetWithSource[] {
	let updatedFacets = [...currentFacets];
	facetsToRemove.forEach((facetToRemove) => {
		if (!facetToRemove.value) {
			updatedFacets = updatedFacets.filter((currentFacet) => !facetIdMatch(currentFacet, facetToRemove));
		} else if (isRangeFacet(facetToRemove)) {
			const { lowRange, highRange } = getRangeFacetData(facetToRemove);
			updatedFacets = updatedFacets.filter(
				(currentFacet) => !isSameFacet(currentFacet, lowRange) && !isSameFacet(currentFacet, highRange)
			);
		} else {
			updatedFacets = updatedFacets.filter((currentFacet) => !isSameFacet(currentFacet, facetToRemove));
		}
	});

	return updatedFacets;
}

export const useSearchResults = (): UseSearchResultsPayload => {
	const history = useHistory();
	const { data: customerData, previousData: previousCustomerData } = useCustomer();
	const searchRequestData = useSearchRequestData();
	const {
		query,
		categoryId,
		offset,
		limit: pageSize,
		sortOption: sortBy,
		facetFilter: facets,
		status,
		manufacturer,
		collectionName
	} = searchRequestData;
	const {
		data,
		previousData,
		loading,
		error,
		refetch: refetchSearch
	} = useQuery<ProductSearchQuery, ProductSearchQueryVariables>(PRODUCT_SEARCH, {
		variables: {
			input: searchRequestData
		},
		fetchPolicy: 'cache-first',
		skip: shouldSkipQuery(query, categoryId, manufacturer, collectionName)
	});

	const isNewLookAndFeel = useFeature(FEATURE_FLAGS.PLP_FACET_LIST_DESIGN);

	useEffect(() => {
		if (error) {
			logError(error.message, error);
		}
	}, [error]);

	const productSearch = data?.productSearch;
	const zipCode = customerData?.customer?.location.zipCode;
	const prevZipCode = previousCustomerData?.customer?.location.zipCode;

	useEffect(() => {
		// update search results when customer location changes
		if (prevZipCode && zipCode !== prevZipCode) {
			refetchSearch().catch(logError);
		}
	}, [prevZipCode, refetchSearch, zipCode]);

	const page = offset + 1;
	const isNonstock = statusIsNonstock(status);

	const updateUrl = useCallback(
		(newFacets: Facet[] | null, newSortBy: ProductSortEnum, newPage: number, newPageSize: number, nonstock) => {
			// Maintains page position when selecting a facet
			const currentWindowPosition = window.scrollY;
			const unregisterListen = history.listen(() => {
				window.scrollTo(0, currentWindowPosition);
				unregisterListen();
			});

			const facetsValue = getFacetsQueryString(newFacets);
			const qsb = new QueryStringBuilder();
			if (query) {
				qsb.push('term', query);
			}
			const defaultPagesize = categoryId > 0 ? DEFAULT_CATEGORY_PAGESIZE : DEFAULT_PAGESIZE;
			qsb.push('page', newPage, newPage > 1)
				.push('pageSize', newPageSize, newPageSize !== defaultPagesize)
				.push('sortBy', newSortBy, newSortBy !== DEFAULT_PRODUCT_SORT)
				.push('facets', facetsValue, Boolean(facetsValue), false)
				.push('ns', nonstock, nonstock);
			history.push({
				search: qsb.build()
			});
		},
		[history, query, categoryId]
	);

	// If paging is off the end of the result set, send them back to the first page.
	useEffect(() => {
		if (!isProductSearchResult(productSearch) || productSearch.count <= 0) {
			return;
		}

		let numPages = Math.ceil(productSearch.count / pageSize);
		if (numPages * pageSize > SEARCH_RESULT_LIMIT) {
			numPages = Math.floor(SEARCH_RESULT_LIMIT / pageSize);
		}
		if (page > numPages) {
			updateUrl(facets, sortBy, 1, pageSize, isNonstock);
		}
	}, [productSearch, facets, page, pageSize, sortBy, isNonstock, updateUrl]);

	return {
		addFacet: (facet: Facet) => {
			const updatedFacets = addFacets(facets, [facet]);
			updateUrl(updatedFacets, sortBy, 1, pageSize, isNonstock);
		},
		removeFacet: (facet: FacetWithSource) => {
			const updatedFacets = removeFacets(facets, [facet]);
			updateUrl(updatedFacets, sortBy, 1, pageSize, isNonstock);
		},
		addFacets: (facetsToAdd?: Facet[]) => {
			if (!facetsToAdd) {
				return;
			}
			const updatedFacets = addFacets(facets, facetsToAdd);
			updateUrl(updatedFacets, sortBy, 1, pageSize, isNonstock);
		},
		removeFacets: (facetsToRemove?: FacetWithSource[]) => {
			if (!facetsToRemove) {
				return;
			}
			const updatedFacets = removeFacets(facets, facetsToRemove);
			updateUrl(updatedFacets, sortBy, 1, pageSize, isNonstock);
		},
		clearAndAddFacets: (facetsToAdd?: Facet[]) => {
			if (!facetsToAdd) {
				return;
			}
			const updatedFacets = addFacets([], facetsToAdd);
			updateUrl(updatedFacets, sortBy, 1, pageSize, isNonstock);
		},
		clearFacets: () => {
			updateUrl([], sortBy, 1, pageSize, isNonstock);
		},
		setSortBy: (newSortBy: ProductSortEnum) => {
			updateUrl(facets, newSortBy, 1, pageSize, isNonstock);
		},
		setPage: (newPage: number) => {
			updateUrl(facets, sortBy, newPage, pageSize, isNonstock);
		},
		setPageSize: (newPageSize: number) => {
			const newPage = getNewPageForPageSizeChange({ page, pageSize }, newPageSize);
			updateUrl(facets, sortBy, newPage, newPageSize, isNonstock);
		},
		setNonstock: (newNonstock: boolean) => {
			const newFacets = newNonstock ? facets : facets.filter((facet) => facet.id !== STOCK_STATUS_FACET);
			updateUrl(newFacets, sortBy, 1, pageSize, newNonstock);
		},
		createGTMEvent,
		facets,
		sortBy,
		page,
		pageSize,
		query,
		categoryId,
		isNonstock,
		isNewLookAndFeel: isNewLookAndFeel || false,
		isNewLookAndFeelSearchHeader: false,
		isNewLookAndFeelProductCard: false,
		request: searchRequestData,
		results: productSearch as ProductSearchResult | undefined,
		previousResults: previousData?.productSearch as ProductSearchResult | undefined,
		loading,
		error,
		redirectUrl: isSearchRedirect(productSearch) ? productSearch.url : undefined,
		flagged: isProductSearchResult(productSearch) && Boolean(productSearch.flagged)
	};
};

export function useFacetGroupResults(facetGroupId: string) {
	const searchRequestData = useSearchRequestData();
	const [loadFacetGroup, { data, loading, error, called }] = useLazyQuery<
		ProductSearchFacetGroupQuery,
		ProductSearchFacetGroupQueryVariables
	>(PRODUCT_SEARCH_FACET_GROUP, {
		variables: {
			input: { facetGroupId, originalQuery: searchRequestData }
		}
	});
	return { loadFacetGroup, facetGroup: data?.productSearchFacetGroup, loading, error, called };
}

export function useProductViewPreference() {
	const { data, loading } = useCustomer();
	const [setViewPreference] = useMutation<SetProductViewPreferenceMutation, SetProductViewPreferenceMutationVariables>(
		SET_PRODUCT_VIEW_PREFERENCE
	);

	return {
		productViewPreference: data?.customer.productViewPreference.preference,
		loading,
		setProductViewPreference: (preference: ProductViewEnum) => setViewPreference({ variables: { preference } }).catch(logError)
	};
}

export function useSearchRequestData(): SearchRequestData {
	const { search, pathname } = useLocation();
	const { category, collectionName, manufacturerName } = useParams<{
		category: string;
		collectionName: string;
		manufacturerName: string;
	}>();
	const { showAsEmployee } = useSiteViewPreference();
	const { getCookie } = useCookies();
	const disableProductBoosts = getCookie(COOKIE_SEARCH_DISABLE_PRODUCT_BOOSTS) === 'true';
	const disableRedirects = getCookie(COOKIE_SEARCH_DISABLE_REDIRECT) === 'true';
	return useMemo(
		() =>
			getSearchRequestData(
				search,
				category,
				collectionName,
				manufacturerName,
				showAsEmployee,
				pathname,
				disableProductBoosts,
				disableRedirects
			),
		[search, category, manufacturerName, collectionName, showAsEmployee, pathname, disableProductBoosts, disableRedirects]
	);
}

function getSearchRequestData(
	search: string,
	category: string,
	collectionName: string,
	manufacturer: string,
	employee: boolean,
	pathname: string,
	disableProductBoosts = false,
	disableSearchRedirect = false
): SearchRequestData {
	const categoryId = !isNaN(Number(category)) ? Number(category) : 0;
	const queryParams = new URLSearchParams(search);
	const legacyUrlStructureExists = isLegacyUrlStructure(queryParams);
	let page: number,
		pageSize: number,
		sortBy: ProductSortEnum,
		facets: Facet[],
		status: ProductStatusEnum[] | undefined = undefined,
		disableRedirect = disableSearchRedirect,
		searchType: string | undefined = undefined;

	if (legacyUrlStructureExists) {
		const data = transformLegacyRequestData(queryParams);
		page = data.page;
		pageSize = data.pageSize;
		sortBy = data.sortBy;
		facets = data.facets;
	} else {
		page = Number(queryParams.get('page') || 1);
		pageSize = Number(queryParams.get('pageSize'));
		sortBy = (queryParams.get('sortBy') as ProductSortEnum) ?? DEFAULT_PRODUCT_SORT;
		facets = parseFacetsQueryString(queryParams.get('facets'));
		disableRedirect = disableRedirect || booleanFromQueryString(queryParams.get('redirect'));

		const isEmployeeNonstockSearch = employee && !categoryId && booleanFromQueryString(queryParams.get('ns'));
		const isNonstockSearchPage = pathname === SUPPORT_NONSTOCK_LINK;
		disableRedirect = disableRedirect || isNonstockSearchPage;

		if (isEmployeeNonstockSearch || isNonstockSearchPage) {
			status = [...NONSTOCK_STATUS];
		}
		if (isNonstockSearchPage) {
			searchType = 'nonstock-keyword';
		}
	}

	if (!PAGESIZE_OPTIONS.includes(pageSize)) {
		pageSize = categoryId > 0 ? DEFAULT_CATEGORY_PAGESIZE : DEFAULT_PAGESIZE;
	}

	if (isNaN(page) || !Number.isInteger(page) || page < 1 || page * pageSize > SEARCH_RESULT_LIMIT) {
		page = 1;
	}
	const query = queryParams.get('term') || '';

	return {
		query,
		categoryId,
		collectionName: collectionName && decodeURIComponent(collectionName),
		manufacturer: manufacturer && decodeURIComponent(manufacturer),
		offset: page - 1,
		limit: pageSize,
		sortOption: sortBy,
		facetFilter: facets,
		status,
		disableRedirect: disableRedirect || undefined,
		disableProductBoosts: disableProductBoosts || undefined,
		searchType
	};
}

// determine if current URL is in legacy format
function isLegacyUrlStructure(queryParams) {
	const params = Array.from(queryParams.keys());
	return params.some((p: string) => LEGACY_PARAMS.includes(p) || LEGACY_FACET_ID.test(p));
}

// used to support and transform legacy URL structures into RBS format
function transformLegacyRequestData(queryParams: URLSearchParams) {
	const sortBy = LEGACY_SORT_MAP[queryParams.get('s') || 'SCORE'] as ProductSortEnum;
	const page = Number(queryParams.get('p') || 1);
	const pageSize = Number(queryParams.get('r'));
	const facets: Facet[] = [];
	queryParams.forEach((value, key) => {
		if (LEGACY_FACET_ID.test(key)) {
			facets.push({ id: key.slice(1), value });
		}
	});
	return { page, pageSize, sortBy, facets };
}

export function useGetSearchSource(): string | null {
	const { search } = useLocation();
	const urlParams = new URLSearchParams(search);
	return urlParams.get('ssrc');
}
